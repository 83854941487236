import * as itowns from 'itowns';
import potree_gui from './potree_gui';
import CloudExtractor from './CloudExtractor';
import AreaMeasurement from './AreaMeasurement';

const crs = 'EPSG:2154';

 // ---------- CREATE A POTREE VIEWER FOR SUPPORTING POINT CLOUD VISUALIZATION : ------------------------------------

const coordinates = new itowns.Coordinates('EPSG:4978');

function potreeViewer(API_URL, dataset, surfaceMax) {
    const viewerDiv = document.getElementById("potree_render_area");
    Potree.setTHREEShaderChunk(itowns.ShaderChunk.target);

    const potreeView = new Potree.Viewer(viewerDiv, {
        measureCoordinateCallback: (position) => {
            return coordinates.setFromVector3(position).as(crs).toVector3();
        },
        dynamicNearFar: false,
        isGeocentric: true,
        crs,
    });

    potreeView.setEDLEnabled(true);
    potreeView.setPointBudget(5_000_000);
    potreeView.setControls(null);
    potreeView.surfaceMax = surfaceMax;

    // ---------- TWEAK POTREE USER INTERFACE : ------------------------------------------------------------------------


    potreeView.scene.addEventListener('measurement_added', (e) => {
        const { measurement } = e;
        if (measurement.name === "Area") {
            measurement.color.set('LightSeaGreen');
            const areaMeasurement =  new AreaMeasurement(measurement, crs, surfaceMax);

            const extractor = new CloudExtractor(
                `${API_URL}/points?poly={}&source=${dataset}`,
                (result) => {
                    potree_gui.succeedExtraction(() => {
                        const dlFrame = document.getElementById("dlframe");
                        dlFrame.src = result.url;
                    });
                },
                potree_gui.failExtraction,
            );

            measurement.onExport = () => {
                extractor.export(areaMeasurement);
            };

            const current_area = $('#current_area');

            measurement.addEventListener('marker_moved', (e) => {
                const area = measurement.getArea() || 0;
                current_area.text(`\u2002${(area / 1000 ** 2).toFixed(3)} km²`);
                if ( area > surfaceMax) {
                    measurement.color.set('red');
                    current_area.css('color', 'red');
                } else {
                    current_area.css('color', 'white');
                    measurement.color.set('LightSeaGreen');
                }
            });

            potree_gui.addButtonExport();
        }
    });

    return potreeView;
}

export default potreeViewer;