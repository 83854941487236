import * as itowns from 'itowns';


class CloudExtractor {
    #_url;
    #_onSuccess;
    #_onFailure;

    #_fetchAttemptNumber = 0;

    constructor(url, onSuccess = () => {}, onFailure = () => {}) {
        this.#_url = url;
        this.#_onSuccess = onSuccess.bind(this);
        this.#_onFailure = onFailure.bind(this);
    }

    getUrlForMeasurement(measurement) {
        const pointsEPSG2154 = measurement.potreeMeasurement.points.map((point) => {
            const coord = new itowns.Coordinates('EPSG:4978', point.position).as(measurement.crs);

            // round precision, now we can lose 1 centimeter
            coord.x = coord.x.toFixed(2);
            coord.y = coord.y.toFixed(2);
            coord.z = coord.z.toFixed(2);

            return coord;
        });

        let stringMeasurement = `${pointsEPSG2154[0].x}_${pointsEPSG2154[0].y}`;
        for (let i = 1; i < pointsEPSG2154.length; i++) {
            stringMeasurement += `,${pointsEPSG2154[i].x}_${pointsEPSG2154[i].y}`;
        }

        return this.#_url.replace('poly={}', `poly=${stringMeasurement}`);
    }

    export(measurement) {
        fetch(this.getUrlForMeasurement(measurement))
            .then(response => {
                switch (response.status) {
                    case 503:
                        this.#_fetchAttemptNumber = 0;
                        this.#_onFailure('connection-failure');
                        break;
                    case 500:
                        this.#_fetchAttemptNumber = 0;
                        this.#_onFailure('server-internal-error');
                        break;
                    case 400:
                        this.#_fetchAttemptNumber = 0;
                        response.json().then(message => {
                            switch (message.id) {
                                case 'BAD_REQUEST_BAD_POLYGON':
                                    this.#_onFailure('points-number');
                                    break;
                                case 'BAD_REQUEST_AREA':
                                    this.#_onFailure('too-big-area');
                                    break;
                                case 'BAD_REQUEST_NO_SOURCE':
                                    this.#_onFailure('no-config-file')
                                    break;
                                default:
                                    break;
                            }
                        });
                        break;
                    case 200:
                        this.#_fetchAttemptNumber = 0;
                        this.#_onSuccess(response);
                        break;
                    case 202:
                        this.#_fetchAttemptNumber += 1;
                        setTimeout(() => { this.export(measurement); }, 1000 * this.#_fetchAttemptNumber);
                        break;
                    default:
                        this.#_fetchAttemptNumber = 0;
                        this.#_onFailure('unknown');
                        break;
                }
            })
            .catch(() => {
                this.#_fetchAttemptNumber = 0;
                this.#_onFailure('connection-failure');
            });
    }
}


export default CloudExtractor;
