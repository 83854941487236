import * as itowns from 'itowns';
import * as THREE from 'three';
import itowns_widgets from './widgets';
import LoadingScreen from './loading-screen';

const loadingScreen = new LoadingScreen({ hidingTimeout: 10000, });

itowns.CRS.defs(
    "EPSG:2154",
    "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 " +
    "+towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
);

// ---------- CREATE A GlobeView FOR SUPPORTING CONTEXTUAL DATA VISUALIZATION : ------------------------------------

function globeView(potreeViewer, dataset) {
	const viewerDiv = document.getElementById("potree_render_area");

	// Define camera initial position.
	const placement = {
	    coord: new itowns.Coordinates('EPSG:4326', 0, 0, 0),
	    range: 5000000,
	    tilt: 23,
	}

	// Create a GlobeView.
	const view = new itowns.GlobeView(viewerDiv, placement, {
	    renderer: potreeViewer.renderer,
	    scene3D: potreeViewer.scene.scene,
        disableFocusOnStart: true,
	});

	// Make atmosphere realistic.
	const atmosphere = view.getLayerById('atmosphere');
	atmosphere.setRealisticOn(true);
	// fix a bug in the atmosphere ( the ground object making a big halo).
	atmosphere.realisticAtmosphere.children[0].visible = false;

	// controls and camera specific setting.
	view.controls.dampingMoveFactor = 0.9;
	view.controls.minDistanceCollision = 0;
	view.controls.minDistance = 20;
	view.camera.camera3D.near = 0.1;
	view.tileLayer.display = true;

	    // ---------- WIDGETS AND LOADING SCREEN : -------------------------------------------------------------------------

    // Add widgets.
    itowns_widgets.setWidgets(view, dataset);

    // Bind loading screen to iTowns viewer.
    loadingScreen.hideLoadingScreenOn(view, itowns.GLOBE_VIEW_EVENTS.GLOBE_INITIALIZED);



    // ---------- DEFINE CUSTOM CONTROLS : -----------------------------------------------------------------------------

    const customControls = {
        PAN: {  // Disable pan movement.
            enable: false,
        },
        MOVE_GLOBE: {  // Change the key bindings for globe rotation.
            enable: true,
            mouseButton: THREE.MOUSE.LEFT,
        },
        ORBIT: {  // Change the key bindings for orbit movement (rotation around the camera target).
            enable: true,
            mouseButton: THREE.MOUSE.MIDDLE,
        },
        DOLLY: {  // Change the key bindings for dolly movement.
            enable: true,
            mouseButton: THREE.MOUSE.RIGHT,
        },
        PANORAMIC: {  // Change the key bindings for panoramic movement (rotation around the camera position).
            enable: true,
            mouseButton: THREE.MOUSE.LEFT,
            keyboard: 17,  // keyCode for the ctrl key.
        },
        TRAVEL_OUT: {  // Allow travel out movement when double right-clicking.
            enable: true,
            mouseButton: THREE.MOUSE.RIGHT,
            double: true,
        },
    };

    // Modify view's control to be set as the custom controls we just defined
    view.controls.states.setFromOptions(customControls);

    // ---------- TWEAK ITOWNS VIEWER TO SUPPORT POTREE VISUALIZATION : ------------------------------------------------

    view.render = () => {
        // Labels rendering :
        view.mainLoop.gfxEngine.label2dRenderer.render(view.scene, view.camera.camera3D);
    };

    // Force the view to resize when changing #potree_render_area div size.
    // This allows view resizing when toggling potree menu.
    new ResizeObserver(() => view.resize()).observe(viewerDiv);

    // Set Potree viewer camera as iTowns viewer camera.
    view.camera.camera3D.zoomTo = () => {};

    // Prevent pointer events on labels container div.
    view.mainLoop.gfxEngine.label2dRenderer.domElement.style['pointer-events'] = 'none';


    // ---------- DISPLAY CONTEXTUAL DATA USING ITOWNS : ---------------------------------------------------------------

    // Add an imagery layer to the scene.
    itowns.Fetcher.json('./libs/itowns/layers/JSONLayers/Ortho.json').then(function _(config) {
        config.source = new itowns.WMTSSource(config.source);
        config.source.zoom = { max: 19, min: 3 };
        view.addLayer(
            new itowns.ColorLayer(config.id, config)
        );
    });

    // Add two elevation layers.
     function addElevationLayerFromConfig(config) {
        config.source = new itowns.WMTSSource(config.source);
        view.addLayer(
            new itowns.ElevationLayer(config.id, config)
        );
    }
    itowns.Fetcher.json('./libs/itowns/layers/JSONLayers/WORLD_DTM.json').then(addElevationLayerFromConfig);
    itowns.Fetcher.json('./libs/itowns/layers/JSONLayers/IGN_MNT_HIGHRES.json').then(addElevationLayerFromConfig);

    // Add a Vector Tile layer to display places names
    view.addLayer(new itowns.LabelLayer('Toponymes', {
        margin: 20,
        source: new itowns.VectorTilesSource({
            style: 'https://wxs.ign.fr/essentiels/static/vectorTiles/styles/PLAN.IGN/standard.json',
            filter: (layer) => {
                if (layer.id === 'toponyme - parcellaire - parcelle' ||
                    layer.id === 'toponyme - parcellaire - adresse') {
                    return false;
                }
                if(layer.paint) {
                    if (layer.paint['text-halo-color']) {
                        layer.paint['text-halo-color'] = 'rgba(240,240,240,0.8)';
                    }
                    if (layer.paint['text-halo-width']) {
                        layer.paint['text-halo-width'] += 2;
                    }
                }
                return true;
            },
            zoom: { max: 19, min: 3 },
        }),
    }));

	return view;
}

export default globeView;