import * as itowns from 'itowns';
import * as THREE from 'three';
import DragSelect from 'dragselect';
import { detect } from 'detect-browser';
import { setupMouseInstructions, getMouseVisual } from './mouse-instructions'

const browser = detect();

const about_text = `<b>Lidar © IGN - 2022<br> iTowns ${itowns.REVISION} - Potree 1.9</b>`;
const titre_viewer = "Démonstrateur Lidar IGN";

let resolve_gui;
const gui_loaded = new Promise((r) => { resolve_gui = r; });

function custom_alert( message, title ) {
    if ( !title )
        title = 'Alert';

    if ( !message )
        message = 'No Message to Display.';

    $('<div></div>').html( message ).dialog({
        title: title,
        resizable: false,
        modal: true,
        buttons: {
            'Ok': function()  {
                $( this ).dialog( 'close' );
            }
        }
    });
}

// IDS of mouse buttons polygons in the svg file for the mouse navigation instructions.
const IDS = {
    'left-click': 'path3209-5-5',
    'right-click': 'path3209-5-2-8',
    'middle-click': 'rect1113-3-7',
    'bottom-arrow': 'path2089-3-9',
    'top-arrow': 'path2089-5',
};
// Config for the mouse navigation instructions.
const config = [
    {
        id: IDS['left-click'],
        text: 'Déplacement',
        style: { fill: '#45a1b9' },
        tr: 'instruction.leftClick',
        tr_title: 'instruction.title_leftClick',
        title: 'Déplacez la souris avec le clique gauche enfoncé pour vous déplacer dans les données ' +
            'affichées.',
    },
    {
        id: IDS['middle-click'],
        text: 'Rotation',
        style: { fill: '#45a1b9' },
        tr: 'instruction.middleClick',
        tr_title: 'instruction.title_middleClick',
        title: 'Déplacez la souris avec le clique molette enfoncé pour pivoter autour du centre de ' +
            'l\'écran.',
    },
    {
        id: [IDS['right-click'], IDS['top-arrow'], IDS['bottom-arrow']],
        text: 'Zoom',
        style: { fill: '#45a1b9' },
        tr: 'instruction.wheel',
        tr_title: 'instruction.title_wheel',
        title: 'Déplacez la souris vers le haut ou le bas avec le clique droit enfoncé pour vous ' +
            'rapprocher ou vous éloigner des données affichées.',
    },
    {
        id: IDS['left-click'],
        text: 'Aller au point',
        style: {
            fill: '#45a1b9',
            text: { field: '2X', xOffset: -7, yOffset: -5, shadow: '0 0 1px black, 1px 1px 3px black' },
        },
        tr: 'instruction.doubleClick',
        tr_title: 'instruction.title_doubleClick',
        title: 'Pressez deux fois le clique gauche pour vous déplacer vers la position du curseur.',
    },
];

function addTranslation(potreeView) {
    // TRANSLATION

    i18n.addResource('fr', 'translation', 'instruction.leftClick', 'Déplacement');
    i18n.addResource('en', 'translation', 'instruction.leftClick', 'Move');
    i18n.addResource('fr', 'translation', 'instruction.middleClick', 'Rotation');
    i18n.addResource('en', 'translation', 'instruction.middleClick', 'Rotation');
    i18n.addResource('fr', 'translation', 'instruction.wheel', 'Zoom');
    i18n.addResource('en', 'translation', 'instruction.wheel', 'Zoom');
    i18n.addResource('fr', 'translation', 'instruction.doubleClick', 'Aller au point');
    i18n.addResource('en', 'translation', 'instruction.doubleClick', 'Go to point');

    i18n.addResource('fr', 'translation', 'instruction.title_leftClick', 'Déplacez souris + bouton gauche enfoncé pour vous déplacer');
    i18n.addResource('en', 'translation', 'instruction.title_leftClick', 'Move mouse + left button down to move');
    i18n.addResource('fr', 'translation', 'instruction.title_middleClick', 'Déplacez souris + molette enfoncé pour pivoter la vue');
    i18n.addResource('en', 'translation', 'instruction.title_middleClick', 'Move mouse + wheel button down to turn the view');
    i18n.addResource('fr', 'translation', 'instruction.title_wheel', 'Déplacez souris + bouton droit enfoncé pour zoomer');
    i18n.addResource('en', 'translation', 'instruction.title_wheel', 'Move mouse + right button down to zoom');
    i18n.addResource('fr', 'translation', 'instruction.title_doubleClick', 'Double clique dans la vue pour se déplacer');
    i18n.addResource('en', 'translation', 'instruction.title_doubleClick', 'Double clic on view to move');

    i18n.addResource('fr', 'translation', 'tb.scene_opt', 'Gestion des couches');
    i18n.addResource('en', 'translation', 'tb.scene_opt', 'Layers manager');
    i18n.addResource('fr', 'translation', 'tb.filters_opt', 'Classes');
    i18n.addResource('en', 'translation', 'tb.filters_opt', 'Classes');

    i18n.addResource('fr', 'translation', 'extract_menu.title', 'Extraction au format LAZ');
    i18n.addResource('fr', 'translation', 'extract_menu.button_download', 'Sélection à Télécharger');
    i18n.addResource('fr', 'translation', 'extract_menu.button_extract', 'Saisir une zone à extraire');
    i18n.addResource('fr', 'translation', 'extract_menu.cancel_extraction', 'Touche echappe pour annuler');
    i18n.addResource('fr', 'translation', 'extract_menu.max_area', `La zone doit être inférieur à ${potreeView.surfaceMax/1000**2} km²`);
    i18n.addResource('fr', 'translation', 'extract_menu.current_area', 'Surface courante :');

    i18n.addResource('en', 'translation', 'extract_menu.title', 'Extract to LAZ format');
    i18n.addResource('en', 'translation', 'extract_menu.button_download', 'Selected to download');
    i18n.addResource('en', 'translation', 'extract_menu.button_extract', 'Draw zone to extract');
    i18n.addResource('en', 'translation', 'extract_menu.cancel_extraction', 'Escape key to cancel');
    i18n.addResource('en', 'translation', 'extract_menu.max_area', `The area must be inferior to ${potreeView.surfaceMax/1000**2} km²`);
    i18n.addResource('en', 'translation', 'extract_menu.current_area', `Current area :`);

    i18n.addResource('fr', 'translation', 'settings.mode_simple', 'Mode simplifié');
    i18n.addResource('fr', 'translation', 'settings.mode_expert', 'Mode avancé');
    i18n.addResource('en', 'translation', 'settings.mode_simple', 'Simple mode');
    i18n.addResource('en', 'translation', 'settings.mode_expert', 'Advanced mode');

    i18n.addResource('fr', 'translation', 'nav_help.title', 'Aide à la navigation');
    i18n.addResource('fr', 'translation', 'simple_attribut.title', 'Attributs');
    i18n.addResource('en', 'translation', 'nav_help.title', 'Navigation help');
    i18n.addResource('en', 'translation', 'simple_attribut.title', 'Attributes');

    i18n.addResource('fr', 'translation', 'tb.rendering_opt', 'Performance');
    i18n.addResource('en', 'translation', 'tb.rendering_opt', 'Performance');

    i18n.addResource('fr', 'translation', 'appearance.nb_max_pts', `Maximum de points affichés à l'écran (diminuer ce nombre pour augmenter les performances d'affichage)`);
    i18n.addResource('en', 'translation', 'appearance.nb_max_pts', `Maximum count points displayed on the screen  (decrease this count to increase performances`);

    i18n.addResource('fr', 'translation', 'menu_cloud.title', 'Propriétés du nuage de points');
    i18n.addResource('en', 'translation', 'menu_cloud.title', 'Points properties');

    i18n.addResource('fr', 'translation', 'scene.pointClouds', 'Nuages de points');
    i18n.addResource('fr', 'translation', 'scene.measurements', 'Mesures');
    i18n.addResource('fr', 'translation', 'scene.other', 'Autres');
    i18n.addResource('fr', 'translation', 'scene.vectors', 'Vecteurs');
    i18n.addResource('en', 'translation', 'scene.pointClouds', 'Point Clouds');
    i18n.addResource('en', 'translation', 'scene.measurements', 'Measurements');
    i18n.addResource('en', 'translation', 'scene.other', 'Other');
    i18n.addResource('en', 'translation', 'scene.vectors', 'Vectors');

    $("#pointclouds_anchor b:last").attr("data-i18n", "scene.pointClouds");
    $("#measurements_anchor b:last").attr("data-i18n", "scene.measurements");
    $("#other_anchor b:last").attr("data-i18n", "scene.other");
    $("#vectors_anchor b:last").attr("data-i18n", "scene.vectors");

    i18n.addResource('fr', 'translation', 'scene.profileFromMeasure', 'obtenir un profil altimétrique');
    i18n.addResource('fr', 'translation', 'scene.export', 'exporter');
    i18n.addResource('fr', 'translation', 'scene.show2dProfile', 'afficher le profil altimétrique');
    i18n.addResource('fr', 'translation', 'scene.copy', 'copier');
    i18n.addResource('en', 'translation', 'scene.profileFromMeasure', 'profile from measure');
    i18n.addResource('en', 'translation', 'scene.export', 'export');
    i18n.addResource('en', 'translation', 'scene.show2dProfile', 'show 2d profile');
    i18n.addResource('en', 'translation', 'scene.copy', 'copy');

    // TODO : these need to be translated when adding a measure/point
    $("input[value='profile from measure']").attr("data-i18n", "[value]scene.profileFromMeasure");
    $("input[value='export']").attr("data-i18n", "[value]scene.export");
    $("input[value='show 2d profile']").attr("data-i18n", "[value]scene.show2dProfile");
    $("img.button-icon[title='copy']").attr("data-i18n", "[title]scene.copy");

    i18n.addResource('fr', 'translation', 'classification.neverClassified', 'jamais classifié');
    i18n.addResource('fr', 'translation', 'classification.unclassified', 'non classifié');
    i18n.addResource('fr', 'translation', 'classification.ground', 'sol');
    i18n.addResource('fr', 'translation', 'classification.lowVegetation', 'végétation basse');
    i18n.addResource('fr', 'translation', 'classification.mediumVegetation', 'végétation moyenne');
    i18n.addResource('fr', 'translation', 'classification.highVegetation', 'végétation haute');
    i18n.addResource('fr', 'translation', 'classification.building', 'bâti');
    i18n.addResource('fr', 'translation', 'classification.lowPoint', 'point bas (bruit)');
    i18n.addResource('fr', 'translation', 'classification.keyPoint', 'point clé');
    i18n.addResource('fr', 'translation', 'classification.water', 'eau');
    i18n.addResource('fr', 'translation', 'classification.overlap', 'chevauchement');
    i18n.addResource('fr', 'translation', 'classification.default', 'défaut');
    i18n.addResource('en', 'translation', 'classification.neverClassified', 'never classified');
    i18n.addResource('en', 'translation', 'classification.unclassified', 'unclassified');
    i18n.addResource('en', 'translation', 'classification.ground', 'ground');
    i18n.addResource('en', 'translation', 'classification.lowVegetation', 'low vegetation');
    i18n.addResource('en', 'translation', 'classification.mediumVegetation', 'medium vegetation');
    i18n.addResource('en', 'translation', 'classification.highVegetation', 'high vegetation');
    i18n.addResource('en', 'translation', 'classification.building', 'building');
    i18n.addResource('en', 'translation', 'classification.lowPoint', 'low point (noise)');
    i18n.addResource('en', 'translation', 'classification.keyPoint', 'key-point');
    i18n.addResource('en', 'translation', 'classification.water', 'water');
    i18n.addResource('en', 'translation', 'classification.overlap', 'overlap');
    i18n.addResource('en', 'translation', 'classification.default', 'default');

    $("span:contains('never classified')").attr("data-i18n", "classification.neverClassified");
    $("span:contains('unclassified')").attr("data-i18n", "classification.unclassified");
    $("span:contains('ground')").attr("data-i18n", "classification.ground");
    $("span:contains('low vegetation')").attr("data-i18n", "classification.lowVegetation");
    $("span:contains('medium vegetation')").attr("data-i18n", "classification.mediumVegetation");
    $("span:contains('high vegetation')").attr("data-i18n", "classification.highVegetation");
    $("span:contains('building')").attr("data-i18n", "classification.building");
    $("span:contains('low point(noise)')").attr("data-i18n", "classification.lowPoint");
    $("span:contains('key-point')").attr("data-i18n", "classification.keyPoint");
    $("span:contains('water')").attr("data-i18n", "classification.water");
    $("span:contains('overlap')").attr("data-i18n", "classification.overlap");
    $("span:contains('default')").attr("data-i18n", "classification.default");

    i18n.addResource('fr', 'translation', 'classification.applyButton', 'Appliquer');
    i18n.addResource('en', 'translation', 'classification.applyButton', 'Apply');

    // TODO : fix this (does not work) : the attribute is well defined, but content is not translated. Needs translation
    //  in spectrum.js package.
    $("button.sp-choose:contains('Apply')").attr("data-i18n", "classification.applyButton");

    // doesn't work :(
    // i18n.t('extract_menu.current_area', { surface: '100' });

    $("#potree_sidebar_container").i18n()
}

function setPotreeGui(view, potreeViewer) {

    if (browser.name == 'firefox') {
        custom_alert('Les performances du navigateur sont très faibles ? Essayez un autre navigateur !', 'Conseil');
    }

    // ######### Titre viewer #########

    potreeViewer.setDescription(titre_viewer);
    $('#potree_description').css('font-family', 'Arial')

    // ######### tools menu #########
    const mTools = $("#menu_tools").next();
    mTools.show();

    // ######### Hide tools #########
    const tools = $('#tools');
    tools.find("img[data-i18n$='angle_measurement']").hide();
    tools.find("img[data-i18n$='circle_measurement']").hide();
    tools.find("img[data-i18n$='volume_measurement']").hide();
    tools.find("img[data-i18n$='annotation']").hide();
    tools.find("img[data-i18n$='Azimuth']").hide();
    // ######### tools menu : Hide clipping and navigation #########
    mTools.children().slice(3).hide();

    // ######### menu scene #########
    $("#menu_scene").next().show();

    // cache export
    $("#scene_export").toggle();

    // ######### appearance menu #########
    const mAppearance = $("#menu_appearance").next();
    mAppearance.show();
    mAppearance.find('.pv-menu-list').children().slice(1).hide();

    // #########  Menu about  #########
    $('#menu_about').next().show();
    const menu_about = $('#menu_about').next().find('.pv-menu-list')
    menu_about.children().hide();

    const about_content = $(`<li>${about_text}</li>`);
    menu_about.append(about_content);
    const piwik_button = $('<li style="cursor: pointer;">Statistiques avec Piwik</li>');
    const piwik_legal = $(`<li>
En vue d’adapter le site <strong>Démonstrateur Lidar</strong> aux demandes de ses visiteurs, l’IGN mesure le nombre de visites, le nombre de pages vues ainsi que l'activité des visiteurs sur le site et leur fréquence de retour.
PIWIK, l'outil de statistiques utilisé par l’IGN, génère un cookie avec un identifiant unique, dont la durée de conservation est limitée à 13 mois. L’outil utilisé collecte également votre adresse IP, afin de déterminer la ville depuis laquelle vous vous connectez. Celle-ci est immédiatement anonymisée après utilisation. L’IGN ne peut donc en aucun cas remonter par ce biais à une personne physique.
Les données recueillies ne sont pas cédées à des tiers ni utilisées à d'autres fins.
<iframe style="border: 0; height: 300px; width: 250px;" src="https://piwik.ign.fr/piwik/public.php?module=CoreAdminHome&action=optOut&language=fr"></iframe>
</li>`);
    piwik_legal.hide();
    piwik_button.click(() => piwik_legal.toggle());
    menu_about.append(piwik_button);
    menu_about.append(piwik_legal);

    $.get('./html/language-selection.html').then((data_language) => {
        // #########  Rebuild language selection  #########
        const elLanguages = $('#potree_languages');
        elLanguages.empty();
        elLanguages.append($(data_language))
        const button_lg = $("#language_options_show");
        button_lg.selectgroup();

        button_lg.find("input").click( (e) => {
            potreeViewer.setLanguage(e.target.value);
            addTranslation(potreeViewer);
            view.dispatchEvent({ type: 'translate', value: e.target.value });
        });

        button_lg.find(`input[value=fr]`).trigger("click");

        const potree_menu = $('#potree_menu');

        // ---------- Load content for simple and expert menus : ----------
        return $.get('./html/custom-potree-menus.html').then((data) => {
            const contentNoobs = $(data);
            potree_menu.prepend(contentNoobs)
            function toggleMenu() {
                let header = $(this);
                let content = $(this).next();

                header.click(() => {
                    content.slideToggle();
                });
            }
            contentNoobs.filter('h3').each(toggleMenu);

            // conserver les menus dans les 2 modes
            $('#menu_about').attr('all_mode', 'true');
            $('#menu_appearance').attr('all_mode', 'true');

            const expert_headers = potree_menu.children('h3').not('[all_mode]').not('[simple_mode]');
            const simple_headers = potree_menu.children('h3[simple_mode]');

            function show() {
                $(this).show()
                $(this).next().hide();
            }
            function hide() {
                $(this).hide()
                $(this).next().hide();
            }

            simple_headers.each(show);
            expert_headers.each(hide);

            // ---------- Add expert mode and simple mode buttons ----------

            const button_mode_simple = $('#button-mode-simple');
            const button_mode_expert = $('#button-mode-expert');

            button_mode_simple.addClass('selected');

            button_mode_simple.click(() => {
                button_mode_simple.addClass('selected');
                button_mode_expert.removeClass('selected');
                simple_headers.each(show);
                expert_headers.each(hide);
            });
            button_mode_expert.click(() => {
                button_mode_simple.removeClass('selected');
                button_mode_expert.addClass('selected');
                simple_headers.each(hide);
                expert_headers.each(show);
            });

            // ---------- NAVIGATION HELP : ----------

            $('#menu_nav_img').append(setupMouseInstructions('images/mouse.svg', config));

            // ---------- EXTRACTION MENU : ----------

            setupExtractionMenu(view, potreeViewer);

            // ---------- MENU PROPRIETE NUAGE DE POINTS ----------
            const pointCloud = potreeViewer.scene.scenePointCloud.children.find(c => c.name == 'lidar');
            const elProperties = $("#scene_object_properties_2");
            const propertiesPanel = new Potree.PropertiesPanel(elProperties, potreeViewer);
            propertiesPanel.set(pointCloud);

            addTranslation(potreeViewer);
            resolve_gui();

            view.controls.lookAtCoordinate(pointCloud.placement || pointCloud.extent, false);
        });
    });
}


function setupExtractionMenu(view, potreeViewer) {
    $('#extraction-instruction-1').prepend(
        getMouseVisual('images/mouse.svg', {
            id: IDS['left-click'],
            style: { fill: '#45a1b9' },
        }),
    );
    $('#extraction-instruction-2').prepend(
        getMouseVisual('images/mouse.svg', {
            id: IDS['right-click'],
            style: { fill: '#45a1b9' },
        }),
    );

    const buttonExtraction = $('#extraction-button-start');
    // cette fonctionnalité n'est pas utilisé pour le moment
    const buttonSelect = $('#select-button-start');
    buttonSelect.toggle();
    const extractionInstruction = $('#extraction-instruction');
    const extractionSuccess = $('#extraction-success');

    let measurement;

    function cancelExtraction() {
        if (extractionInstruction.hasClass('active')) {
            buttonExtraction.click();
        }
        if (measurement) {
            potreeViewer.scene.removeMeasurement(measurement);
        }
    }

    $(document).keyup(function(e) {
        if (e.key === "Escape") {
            cancelExtraction();
        }
    });

    const size_toggle = 280;

    const onExport = (event) => {
        if (event.which === 3 && measurement.getArea() > potreeViewer.surfaceMax) {
            custom_alert( 'Surface trop grande', 'Erreur extraction');
            cancelExtraction();
        } else if (event.which === 3 && measurement.getArea() <= potreeViewer.surfaceMax ) {
            event.preventDefault();

            extractionInstruction.slideToggle(size_toggle);
            extractionInstruction.removeClass('active');
            $('#extraction-wait').addClass('active');


            measurement.onExport();
            buttonExtraction.click();
            view.controls.states.enabled = true;
        }
    };

    // ##################################################################
    // selection des emprises pour générer les urls de pré-paquets
    // cette foncitonnalitée n'est pas activé
    const selector = document.createElement('div')

    selector.style.position = 'absolute'
    selector.style.background = 'rgba(255, 0, 0, 0.2)'
    selector.style.border = '1.5px solid rgba(255, 0, 0, 0.8)'
    selector.style.display = 'none'
    selector.style.pointerEvents = 'none' // fix for issue #8 (ie11+)

    const ds = new DragSelect({
        area: document.getElementById('potree_render_area'),
        selector
    });

    ds.stop();

    const endSelection =  () => {
        view.controls.states.enabled = true;
        view.domElement.removeEventListener('mouseup', endSelection);
        ds.stop();
    };

    buttonSelect.click(() => {
        view.controls.states.enabled = false;
        view.domElement.addEventListener('mouseup', endSelection);
        ds.start();
    });

    buttonExtraction.click(() => {
        buttonExtraction.toggleClass('selected');

        // Hide previous success info if displayed.
        if (extractionSuccess.hasClass('active')) {
            extractionSuccess.slideToggle(size_toggle);
            extractionSuccess.removeClass('active');
        }
        // Hide previous error info if displayed.
        $('.extraction-error').removeClass('active');


        if (buttonExtraction.hasClass('selected')) {
            view.controls.states.enabled = false;
            if (measurement) {
                potreeViewer.scene.removeMeasurement(measurement);
            }

            extractionInstruction.slideToggle(size_toggle);
            extractionInstruction.addClass('active');

            measurement = potreeViewer.sidebar.measuringTool.startInsertion({
                showDistances: false,
                showArea: true,
                closed: true,
                name: 'Area',
            });

            view.domElement.addEventListener('mouseup', onExport);
        } else {
            // Hide instructions if displayed.
            if (extractionInstruction.hasClass('active')) {
                extractionInstruction.slideToggle(size_toggle);
                extractionInstruction.removeClass('active');
            }

            view.controls.states.enabled = true;
            view.domElement.removeEventListener('mouseup', onExport);
        }
    });
}


function failExtraction(error) {
    const extractionWait = $('#extraction-wait');
    // If extraction comes from simplified extraction GUI.
    if (extractionWait.hasClass('active')) {
        extractionWait.removeClass('active');
        $(`#extraction-error-${error}`).addClass('active');
    }
}


function succeedExtraction(buttonCallback) {
    const extractionWait = $('#extraction-wait');

    // If extraction comes from simplified extraction GUI.
    if (extractionWait.hasClass('active')) {
        extractionWait.removeClass('active');
        const extractionSuccess = $('#extraction-success');
        extractionSuccess.slideToggle(200);
        extractionSuccess.addClass('active');
        $('#extraction-button-download').click(buttonCallback);
    } else {
        // If extraction was performed in the expert mode.
        buttonCallback();
    }

}


function addAttributesGui(pointcloud) {
    gui_loaded.then(() => {
        const attributes = pointcloud.pcoGeometry.pointAttributes.attributes;

        let options = [];

        options.push(...attributes.map(a => a.name));

        const blacklist = [
            'POSITION_CARTESIAN',
            'position',
            'gps-time',
            'returnNumber',
            'number of returns',
            'return number',
            'source id'
        ];

        options = options.filter(o => !blacklist.includes(o));

        const attributeSelection = $('#optMaterial_simple');
        for(let option of options){
            let elOption = $(`<option>${option}</option>`);
            attributeSelection.append(elOption);
        }

        const material = pointcloud.material;
        const updateMaterial = (event, ui) => {
            let selectedValue = attributeSelection.selectmenu().val();
            material.activeAttributeName = selectedValue;
        };

        const updateMaterialPanel = () => {
            attributeSelection.val(material.activeAttributeName).selectmenu('refresh');
        };

        material.addEventListener('active_attribute_changed', updateMaterialPanel)

        attributeSelection.selectmenu({change: updateMaterial});
        updateMaterialPanel();
    });
}

function addButtonExport() {
    let tree = $('#jstree_scene');
    tree.on("select_node.jstree", (e, data) => {
        let object = data.node.data;
        const measurement_area = $('#measurement_area');
        const panelArea = $('#measurement_area').parent();
        const exportButton = panelArea.find("#area_export")[0];
        if(!exportButton && measurement_area[0]) {
            const content = $(`
                <li style="display: grid; grid-template-columns: auto auto; grid-column-gap: 5px; margin-top: 10px">
                        <input id="area_export" type="button" value="export"/>
                </li>
            `);
            panelArea.append(content);

            panelArea.find("#area_export").click(() => {
                if (object.onExport) {
                    object.onExport();
                }
            });
        }
    });
}


function setupTerrainVisibility(view, callback) {
    const tree = $(`#jstree_scene`);

    tree.on("uncheck_node.jstree", (e, data) => {
        if (data.node.data?.isTiledGeometryLayer) {
            callback(false);
        }
    });

    tree.on("check_node.jstree", (e, data) => {
        if (data.node.data?.isTiledGeometryLayer) {
            callback(true);
        }
    });

    const terrain = tree.jstree('create_node', "other", {
            "text": "Terrain",
            "icon": `${Potree.resourcePath}/icons/earth_controls.svg`,
            "data": view.tileLayer
        },
        "last", false, false);
    tree.jstree(view.tileLayer.visible ? "check_node" : "uncheck_node", terrain);
}


export default {
    setPotreeGui,
    addAttributesGui,
    addButtonExport,
    setupTerrainVisibility,
    failExtraction,
    succeedExtraction,
}
