function updateSvgNode(svgNode, style) {
    svgNode.style.display = 'inline';

    for (const key in style) {
        if (key === 'text') {
            const boundingBox = svgNode.getBBox();

            const textNode = document.createElementNS('http://www.w3.org/2000/svg', 'text');

            textNode.style['font-size'] = style[key].size || '1em';
            textNode.style['font-family'] = 'sans-serif';
            textNode.style['font-weight'] = 'bold';
            textNode.style.fill = style[key].color || 'white';
            textNode.style['text-shadow'] = style[key].shadow;
            textNode.style['text-anchor'] = 'middle';
            textNode.style['dominant-baseline'] = 'middle';

            textNode.setAttribute(
                'x',
                boundingBox.x + (boundingBox.width / 2) + (style[key].xOffset || 0),
            );
            textNode.setAttribute(
                'y',
                boundingBox.y + (boundingBox.height / 2) + (style[key].yOffset || 0),
            );

            textNode.innerHTML = style[key].field;

            svgNode.parentElement.appendChild(textNode);
        } else {
            svgNode.style[key] = style[key];
        }
    }
}


function getMouseVisual(svgSource, instruction) {
    const svgContainer = document.createElement('object');
    svgContainer.data = svgSource;
    svgContainer.style['transform'] = 'translate(3px, 0px)';

    svgContainer.addEventListener('load', () => {
        if (Array.isArray(instruction.id)) {
            for (const id of instruction.id) {
                updateSvgNode(svgContainer.contentDocument.getElementById(id), instruction.style);
            }
        } else {
            updateSvgNode(svgContainer.contentDocument.getElementById(instruction.id), instruction.style);
        }
    });

    return svgContainer;
}


function setupMouseInstructions(svgSource, instructions) {
    const domElement = document.createElement('div');
    domElement.id = 'mouse-instructions';

    for (const instruction of instructions) {
        const instructionContainer = document.createElement('div');
        instructionContainer.classList.add('instruction');
        instructionContainer.setAttribute('data-i18n', `[title]${instruction.tr_title}`);
        domElement.appendChild(instructionContainer);

        instructionContainer.appendChild(
            getMouseVisual(instruction.svgSource || svgSource, instruction),
        );

        const textContainer = document.createElement('div');
        textContainer.classList.add('instruction-text');
        textContainer.setAttribute('data-i18n', instruction.tr);
        instructionContainer.appendChild(textContainer);
    }

    return domElement;
}


export {
    setupMouseInstructions,
    getMouseVisual,
}