/* global itowns */
const DEFAULT_OPTIONS = {
    hidingTimeout: 3000,
}


class LoadingScreen {
    #_hidingTimeout;

    constructor(options = {}) {
        this.parentElement = options.parentElement || document.body;
        this.#_hidingTimeout = options.hidingTimeout || DEFAULT_OPTIONS.hidingTimeout;

        this.domElement = options.domElement || document.createElement('div');
        this.parentElement.appendChild(this.domElement)

        this.domElement.id = this.domElement.id || 'itowns-loader';
        this.domElement.innerHTML = '<div>'
            + '<span class="c1">i</span>'
            + '<span class="c2">T</span>'
            + '<span class="c3">o</span>'
            + '<span class="c4">w</span>'
            + '<span class="c5">n</span>'
            + '<span class="c6">s</span>'
        + '</div>';
    }

    hideLoadingScreenOn(eventDispatcher, event) {
        const onHide = () => {
            this.domElement.classList.add('hidden');

            this.domElement.addEventListener('transitionend', () => {
                this.parentElement.removeChild(this.domElement);
            });

            eventDispatcher.removeEventListener(event, onHide);
        };

        eventDispatcher.addEventListener(event, onHide);
        setTimeout(onHide, this.#_hidingTimeout);
    }
}


export default LoadingScreen;