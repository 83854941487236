import * as itowns from 'itowns';
import PotreeViewer from './potreeViewer';
import globeView from './sig_3d';
import potree_gui from './potree_gui';
import itowns_widgets from './widgets';
import * as THREE from 'three';

const API_URL = process.env.API_URL || "/api";

const conf_alex = {
    ept: `https://alex.ign.fr/3d/LAZ_ALTI_CLASS/EPT_Altitude_classe_4978/ept.json`,
    pivot: `https://alex.ign.fr/3d/LAZ_ALTI_CLASS/EPT_Altitude_classe_4978/pivotTHREE_7.359564451156049_44.003580514414764.json`,
}

const _box = new THREE.Box3();

const cSouthWest =  new itowns.Coordinates('EPSG:4326', 0, 0, 0);
const cNorthEast =  new itowns.Coordinates('EPSG:4326', 0, 0, 0);

function fromBox3(crs, box) {
    if (itowns.CRS.isGeocentric(crs)) {
        box = _box.copy(box);

        cSouthWest.crs = crs;
        cSouthWest.setFromVector3(box.min).as('EPSG:4326', cSouthWest).toVector3(box.min);
        cNorthEast.crs = crs;
        cNorthEast.setFromVector3(box.max).as('EPSG:4326', cNorthEast).toVector3(box.max);
        crs = 'EPSG:4326';
    }

    return new itowns.Extent(crs, {
        west: box.min.x,
        east: box.max.x,
        south: box.min.y,
        north: box.max.y,
    });
}

// Entry-point called by HTML index page
function main() {
    const urlParams = new URLSearchParams(window.location.search);
    const dataset = urlParams.get('dataset') || '';

    const longitude = urlParams.get('longitude');
    const latitude = urlParams.get('latitude');
    const range = urlParams.get('range');

    const attribute = urlParams.get('attribute') || 'rgba';

    fetch(`${API_URL}/datasets/${dataset}`)
      .then(res => res.status === 200 ? res.json() : {})
      .then((/** @type {{ept?: string; pivot?: string; maxArea?: string}} */ conf) => {
              if (dataset == 'alex') {
                conf = conf_alex;
              }
              if (conf && conf.ept && conf.pivot) {
                    if (longitude && latitude && range) {
                        conf.placement = {
                            coord: new itowns.Coordinates('EPSG:4326', Number(longitude), Number(latitude)),
                            range: Number(range),
                            tilt: 89
                        };
                    }
                    conf.attribute = attribute;
                    loadData(dataset, conf);
              } else {
                    window.location.search = "";
              }
      })
}

function loadData(dataset, conf) {
    dataset = dataset || 'EI19DPAR';
    const eptURL = conf.ept;
    const pivotURL = conf.pivot;
    const surfaceMax = conf.maxArea || 100000;

    const potreeViewer = PotreeViewer(API_URL, dataset, surfaceMax);
    const view = globeView(potreeViewer, dataset);

    potreeViewer.scene.cameraP = view.camera.camera3D;


    potreeViewer.loadGUI(() => {
        potreeViewer.setLanguage('fr');
        potreeViewer.toggleSidebar();
        potreeViewer.setBackground('black');
    });


    // ---------- BIND ITOWNS DATA VISIBILITY TO POTREE SCENE GUI MENU : -----------------------------------------------

    potreeViewer.onGUILoaded(() => {
        potree_gui.setupTerrainVisibility(view, (visible) => {
            view.tileLayer.visible = true;
            view.tileLayer.opacity = visible ? 1.0 : 0.001;
            view.getLayerById('atmosphere').visible = visible;
            view.controls.handleCollision = visible;
            view.notifyChange(view.camera.camera3D, true);
            // view.controls.minDistance = visible ? 150 : 50;

            // Hide ortho-images layer and both DEM layers.
            if (view.getLayerById('Ortho')) {
                view.getLayerById('Ortho').visible = visible;
            }
            view.getLayers(l => l.isElevationLayer).forEach(l => {
                // TODO : perhaps we don't need to update ElevationLayer. We could just hide it since a 0 scale is the
                //  same as no ElevationLayer at all.
                l.scale = visible ? 1.0 : 0.0;
                view.notifyChange(l);
            })
        });
    });



    // ---------- DISPLAY POINT CLOUD DATA USING POTREE : --------------------------------------------------------------

    itowns.Fetcher.json(pivotURL).then(
        (pivot) => {
            const pivotTHREE = new THREE.ObjectLoader().parse(pivot);
            Potree.loadPointCloud(eptURL, 'lidar', function(e) {
                const pointCloud = e.pointcloud;
                const material = pointCloud.material;
                potreeViewer.scene.addPointCloud(pointCloud);
                pointCloud.getAttribute("intensity").range = dataset == 'alex' ? [0, 3700] : [0, 10000];
                material.size = 0.8;
                material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
                material.intensityRange = dataset == 'alex' ? [0, 3700] : [0, 10000];
                material.shape = Potree.PointShape.CIRCLE
                material.isGeocentric = true;
                if (dataset == 'alex') {
                    material.classification['3'].visible = false;
                    material.classification.DEFAULT.visible = false;
                }

                if (conf.attribute == 'rgba' || conf.attribute == 'intensity' || conf.attribute == 'classification') {
                    material.activeAttributeName = conf.attribute;
                }

                const pivot = new itowns.Coordinates('EPSG:4978', 0, 0, 0);
                pivot.setFromVector3(pivotTHREE.position);

                pointCloud.position.copy(pivotTHREE.position);
                pointCloud.quaternion.copy(pivotTHREE.quaternion);
                pointCloud.updateMatrixWorld(true);

                if (conf.placement) {
                    pointCloud.placement = conf.placement;
                    itowns_widgets.setCenterButton(conf.placement);
                } else {
                    // calcul de l'emprise géographique
                    const bbox = pointCloud.boundingBox.clone().applyMatrix4(pointCloud.matrixWorld);
                    pointCloud.extent = fromBox3('EPSG:4978', bbox);

                    itowns_widgets.setCenterButton(pointCloud.extent);
                }

                potree_gui.addAttributesGui(pointCloud);
                potree_gui.setPotreeGui(view, potreeViewer);
            });
        }
    );
}


export { main, itowns };
