import * as itowns from 'itowns';


const coordinates = new itowns.Coordinates('EPSG:4978');


class AreaMeasurement {
    #areaPoints = [];

    constructor(potreeMeasurement, crs, surfaceMax) {
        this.potreeMeasurement = potreeMeasurement;
        this.crs = crs;
        // TODO Use surfaceMax to update area label color (red/green) to give feedback to user

        // ---------- TWEAK AREA MEASUREMENT TOOL : ----------

        // Override Potree area measure method. The original Potree method gives a planar area delimited from given
        // points (summits of the area). The area returned by the method is computed from `x` and `y` components of
        // the points coordinates.
        //
        // By default, the points coordinates passed to the method are in iTowns viewer CRS, which is geocentric.
        // Therefore, computing an area over the `x` and `y` components of these coordinates is incorrect - it
        // actually computes its orthogonal projection on the equatorial plane.
        //
        // To fix this, we transform each coordinates of the area summits in the point cloud CRS, which is
        // geographic. We store each transformed coordinates in an array. Finally, we override Potree area measure
        // method so that it computes area from this array.


        // Array containing the geographic coordinates of each area summits
        this.#areaPoints.push(new itowns.Coordinates(this.crs));

        // Add a Coordinates to the array when an area summit is created.
        this.potreeMeasurement.addEventListener('marker_added', () => {
            this.#areaPoints.push(new itowns.Coordinates(this.crs))
        }, false);

        // Translate the area summit coordinates from itowns viewer CRS to geographic CRS when the summit is
        // moved. It also happens when the summit is created.
        this.potreeMeasurement.addEventListener('marker_moved', (event) => {
            coordinates.setFromVector3(event.position).as(this.crs, this.#areaPoints[event.index]);
        }, false);

        // Clear the coordinates array when we remove an area summit.
        this.potreeMeasurement.addEventListener('marker_removed', () => {
            this.#areaPoints.splice(this.#areaPoints.length - 1, 1);
        }, false);

        // Override potree `Measure.getArea` method to use projected coordinates to compute area
        this.potreeMeasurement.getArea = this.getArea.bind(this);
    }

    getArea() {
        let area = 0;
        let j = this.#areaPoints.length - 1;

        for (let i = 0; i < this.#areaPoints.length; i++) {
            let p1 = this.#areaPoints[i];
            let p2 = this.#areaPoints[j];
            area += (p2.x + p1.x) * (p1.y - p2.y);
            j = i
        }

        return Math.abs(area / 2);
    }
}


export default AreaMeasurement;
