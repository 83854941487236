import * as THREE from 'three';
import * as itowns from "itowns";
import * as itowns_widgets from 'itowns/widgets';

const TRANSLATE_OPTIONS = {
    en: {
        compass: 'Rotate the camera to face North',
        toggle3D: 'Tilt the camera',
        zoomIn: 'Zoom in',
        zoomOut: 'Zoom out',
        help: 'Show help',
        centerButton: 'Center camera on point cloud',
        searchbar: 'Search a location in France',
    },
    fr: {
        compass: 'Orienter la caméra vers le Nord',
        toggle3D: 'Incliner la caméra',
        zoomIn: 'Zoomer',
        zoomOut: 'Dézoomer',
        help: 'Aide',
        centerButton: 'Centrer la caméra sur le nuage de points',
        searchbar: 'Chercher un lieu en France',
    },
};

let navigation;
let itownsViewer;

itowns.CRS.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

function setWidgets(view, dataset) {
    itownsViewer = view;
    if (dataset == 'lidarhd') {
        dataset = 'nimes';
    }
    const empriseUrl = `/data/${dataset}/metadata/emprise.geojson`;

    // ---------- ADD NAVIGATION WIDGET : ----------

    navigation = new itowns_widgets.Navigation(view, {
        position: 'bottom-right',
    });

    const help = navigation.addButton(
        'Aide',
        '?',
        TRANSLATE_OPTIONS.en.help,
        () => $("#dialog").dialog({width: 550}),
        'button-bar'
    );



    // ---------- ADD MINIMAP WIDGET : ----------

    // Create a ColorLayer that shall be displayed on the minimap.
    const minimapColorLayer = new itowns.ColorLayer('minimap-background', {
        source: new itowns.VectorTilesSource({
            style: 'https://wxs.ign.fr/essentiels/static/vectorTiles/styles/PLAN.IGN/gris.json',
            // We don't display mountains and plot related data to ease visualisation
            filter: (layer) => !layer['source-layer'].includes('oro_')
                && !layer['source-layer'].includes('parcellaire'),
        }),
        addLabelLayer: true,
    });


    // TODO unifié les projections des emprises
    let crs = 'EPSG:2154';

    const dataset_lidarhd = ['nimes', 'lidarhd', 'lidarhd-class']

    if (dataset_lidarhd.includes(dataset)) {
        crs = 'EPSG:4326';
    }

    let width = 1;
    if (dataset == 'lidarhd-class') {
        width = {
            stops: [[0, 6], [5, 4], [10, 1]]
        };
    }
    // Create a minimap.
    const minimap = new itowns_widgets.Minimap(view, minimapColorLayer, {
        cursor: '⊙',
        size: 200,
    });

    if (dataset != `alex`) {
        const cloudExtent = new itowns.ColorLayer('point-cloud-extent', {
            source: new itowns.FileSource({
                url: empriseUrl,
                format: 'application/json',
                crs: crs,
            }),
            style: new itowns.Style({
                fill: {
                    color: '#497885',
                    opacity: 0.7,
                },
                stroke: {
                    width: width,
                    color: '#407885',
                },
            }),
            transparent: true,
            accurate: true,
        });

        // Display the point cloud extent on the minimap.
        minimap.view.addLayer(cloudExtent);
    }

    // Add interaction with minimap : travel to cursor location when double-clicking.
    const cursorCoordinates = new itowns.Coordinates(minimap.view.referenceCrs);
    minimap.domElement.addEventListener('dblclick', (event) => {
        minimap.view.pickCoordinates(event, cursorCoordinates);
        view.controls.lookAtCoordinate({ coord: cursorCoordinates, time: 800 });
    });



    // ---------- ADD A SEARCHBAR : ----------

    const geocodingOptions = {
        url: new URL(
            'https://wxs.ign.fr/ayxvok72rcocdyn8xyvy32og/ols/apis/completion?text=&type=StreetAddress,' +
            'PositionOfInterest',
        ),
        "parser": (response) => {
            const map = new Map();
            response.results.forEach(location => {
                map.set(location.fulltext, {
                    coord: new itowns.Coordinates('EPSG:4326', location.x, location.y),
                    classification: location.classification,
                });
            });
            return map;
        },
        "onSelected": (info) => {
            view.controls.lookAtCoordinate({
                coord: info.coord,
                range: info.classification == 7 ? 100 : 15000 / info.classification,
                tilt: 89,
                heading: 0,
            });
        },
    };

    const searchbar = new itowns_widgets.Searchbar(view, geocodingOptions, {
        maxSuggestionNumber: 15,
        position: 'top-right',
    });



    // ---------- TRANSLATE WIDGETS CONTENT WHEN TRANSLATING POTREE SIDEBAR : ----------

    view.addEventListener('translate', (event) => {
        switch (event.value) {
            case 'fr':
                navigation.compass.title = TRANSLATE_OPTIONS.fr.compass;
                navigation.toggle3D.title = TRANSLATE_OPTIONS.fr.toggle3D;
                navigation.zoomIn.title = TRANSLATE_OPTIONS.fr.zoomIn;
                navigation.zoomOut.title = TRANSLATE_OPTIONS.fr.zoomOut;
                help.title = TRANSLATE_OPTIONS.fr.help;
                document.getElementById('searchbar-autocompletion-form').children[0].setAttribute(
                    'placeholder', TRANSLATE_OPTIONS.fr.searchbar,
                );
                break;
            case 'en':
            default:
                navigation.compass.title = TRANSLATE_OPTIONS.en.compass;
                navigation.toggle3D.title = TRANSLATE_OPTIONS.en.toggle3D;
                navigation.zoomIn.title = TRANSLATE_OPTIONS.en.zoomIn;
                navigation.zoomOut.title = TRANSLATE_OPTIONS.en.zoomOut;
                help.title = TRANSLATE_OPTIONS.en.help;
                document.getElementById('searchbar-autocompletion-form').children[0].setAttribute(
                    'placeholder', TRANSLATE_OPTIONS.en.searchbar,
                );
                break;
        }
    });


    // ---------- CURSOR COORDINATES : ----------
    const setText = (lon, lat, alt) => {
        $('#cursor-lon').text(lon ?? '---------');
        $('#cursor-lat').text(lat ?? '---------');
        $('#cursor-alt').text(alt ?? '---');
    };
    const cursorWorldPosition = new THREE.Vector3();
    const coordinatesView = new itowns.Coordinates(view.referenceCrs);
    const coordinates4326 = new itowns.Coordinates('EPSG:4326');
    view.domElement.addEventListener('mousemove', (event) => {
        // Don't update coordinates if user is moving to prevent freezes.
        if (view.controls.states.currentState === view.controls.states.NONE) {
            if (view.getPickingPositionFromDepth(view.eventToViewCoords(event), cursorWorldPosition)) {
                coordinatesView.setFromVector3(cursorWorldPosition).as('EPSG:4326', coordinates4326)
                setText(
                    `${coordinates4326.longitude.toFixed(6)}°`,
                    `${coordinates4326.latitude.toFixed(6)}°`,
                    `${coordinates4326.altitude.toFixed(1)} m`,
                );
            } else { setText(); }
        }
    });
    view.domElement.addEventListener('mouseleave', () => { setText(); });

}


function setCenterButton(centerPosition) {
    const centerButton = navigation.addButton(
        'Center',
        '⊚',
        TRANSLATE_OPTIONS.en.centerButton,
        () => {
            console.log('centerPosition', centerPosition);
            itownsViewer.controls.lookAtCoordinate(centerPosition, false);
        },
        'button-bar',
    );

    itownsViewer.addEventListener('translate', (event) => {
        switch (event.value) {
            case 'fr':
                centerButton.title = TRANSLATE_OPTIONS.fr.centerButton;
                break;
            case 'en':
            default:
                centerButton.title = TRANSLATE_OPTIONS.en.centerButton;
                break;
        }
    });
}


export default {
    setWidgets,
    setCenterButton,
};
